@import '../../../assets/style/variables';

.art-work-page {
    display: flex;

    .description {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 

        p {
            padding: 10px;
        }
    }

    .carousel {
        flex: 3;
    }

    .links {
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: $medium-bp) {
        flex-direction: column-reverse;
    }
}