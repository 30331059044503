@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500;600;700;800&family=Nothing+You+Could+Do&family=Roboto:wght@100&display=swap');

.App {
  text-align: center;
  font-family: 'Dosis','Roboto', sans-serif;
font-weight: 700 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.App {
  min-height: 100vh; /* make sure app fills the whole viewport height */
  display: flex;
  flex-direction: column;
  position: relative;
 /*// min-width: 90vh;*/
  overflow-x: hidden;
}

main {
  flex: 1; /* allows main content to grow and push footer down */
}

footer {
  z-index: 1000; /* Adjust if necessary to bring footer to front */

}
